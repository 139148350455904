<template>
  <v-dialog v-model="dialog">
    <v-card :loading="loading">
      <v-card-title>
        جزئیات پست "{{ selectedData.title ? selectedData.title : "" }}"
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <small>نام پست</small>
            <h3 class="black--text">{{ selectedData.title }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.category && selectedData.category.name"
          >
            <small>دسته بندی پست</small>
            <h3 class="black--text">{{ selectedData.category.name }}</h3>
          </v-col>
        </v-row>
        <h3 class="mt-5">توضیحات</h3>
        <h4 class="mt-4 fix-html-images" v-html="selectedData.desc"></h4>
        <v-row align="center" justify="end" class="mt-8 mb-3">
          <v-btn
            class="ma-2"
            dark
            small
            color="red"
            :loading="loading"
            @click="setStatus(2)"
          >
            <v-icon small dark>mdi-close</v-icon>
            <!-- {{ row.status == 2 ? "پست رد شده" : "رد پست" }} -->
            رد پست
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            small
            color="success"
            :loading="loading"
            @click="setStatus(1)"
          >
            <v-icon small dark>mdi-check-bold</v-icon>

            <!-- {{ row.status == 1 ? "پست تایید شده" : "تایید پست" }} -->
            تایید پست
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
  methods: {
    setStatus(status) {
      this.loading = true;
      MyAxios.post("/posts/" + this.selectedData.id + "/update", {
        status,
      })
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.$root.$emit("toast", {
            text: "وضعیت نظر با موفقیت تغییر کرد.",
            type: "success",
          });
          this.$emit("GetList");
          // this.GetList();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
