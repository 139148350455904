<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <posts-modal
      ref="detailsModal"
      :selectedData="selectedData"
      @GetList="GetList"
    />
    <MyDataTable
      title="پست های موجود"
      subtitle="مدیریت پست"
      addtitle="ایجاد پست جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onDetailsClick(row)"
            >
              <v-icon small dark>mdi-apps</v-icon>
              مشاهده
            </v-btn>
          </template>
          <span dir="ltr">{{ "moholand.com/articles/" + row.slug }}</span>
        </v-tooltip>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.title }}</td>
        <td>
          {{ row.user ? row.user.first_name + " " + row.user.last_name : "-" }}
        </td>
        <td>{{ row.category ? row.category.name : "-" }}</td>
        <!-- <td>{{ row.status }}</td> -->
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import PostsModal from "../../../../components/Panel/PostsModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    PostsModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/posts/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["category", "user"],
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/posts/edit/" + item.slug,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      // this.selectedData = item;
      // this.$refs.detailsModal.dialog = true;
      window.open("/articles/" + item.slug, "_blank");
    },
    deleteItem(item) {
      MyAxios.delete(`/posts/${item.slug}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/menus/index",
      pathParams: {},
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "title",
        },
        { text: "توسط", value: "user_id" },
        { text: "دسته بندی", value: "category_id" },
        // { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
